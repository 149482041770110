.FormWrapper {
	border-radius: 25px;
	min-width: 400px;
	width: 60%;
	margin: auto;
	margin-bottom: 0px;
	text-align: left;
	background: rgba(0,0,255,.5);
}

.Form {
	width: 60%;
	margin: auto;
	padding: 20px;
}

.Form label {
	width: 30%;
	min-width: 150px;
}

.Form form #subject {
	width: 90%;
}

.Form form #body {
	width: 90%;
	height: 60px;
}