body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
	text-decoration: none;
}

input {
	display: block;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Banner {
	background-color: pink;
	font-size: 36px;
	width: 100%;
}
.AboutWrapper {
	border-radius: 25px;
	min-width: 400px;
	width: 60%;
	margin: auto;
	background: rgba(0,128,0,.9)
}

.About {
	width: 80%;
	margin: auto;
}

.About p {
	font-size: 18px;
	padding: 5px;
}
.FormWrapper {
	border-radius: 25px;
	min-width: 400px;
	width: 60%;
	margin: auto;
	margin-bottom: 0px;
	text-align: left;
	background: rgba(0,0,255,.5);
}

.Form {
	width: 60%;
	margin: auto;
	padding: 20px;
}

.Form label {
	width: 30%;
	min-width: 150px;
}

.Form form #subject {
	width: 90%;
}

.Form form #body {
	width: 90%;
	height: 60px;
}
.SlideShowWrapper {
	border-radius: 25px;
	min-width: 400px;
	width: 60%;
	height: 600px;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	background: rgba(255,0,0,.5);
}

.SlideShow {
	width: 90%;
	margin: auto;
}
.Image {
	margin: 20px;
	height: 100%;
}
