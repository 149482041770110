.SlideShowWrapper {
	border-radius: 25px;
	min-width: 400px;
	width: 60%;
	height: 600px;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	background: rgba(255,0,0,.5);
}

.SlideShow {
	width: 90%;
	margin: auto;
}