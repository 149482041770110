.AboutWrapper {
	border-radius: 25px;
	min-width: 400px;
	width: 60%;
	margin: auto;
	background: rgba(0,128,0,.9)
}

.About {
	width: 80%;
	margin: auto;
}

.About p {
	font-size: 18px;
	padding: 5px;
}